import cn from "classnames";
import { debounce } from "lodash";
import moment from "moment";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { chartActions } from "redux/modules/common/chart/actions";
import { chartStatusesRLUColorsSelector, chartStatusesSelector } from "redux/modules/common/chart/selectors";
import { updateChartStatuses } from "redux/modules/common/chart/thunks";
import {
  CHART_TABS,
  ChartSatusConfig,
  ChartStatusDetailed,
  ChartStatusDetails,
  ChartStatusTypeResources,
  ChartStatusTypeWork,
} from "redux/modules/common/chart/types";

import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";

import ChartInterval from "../ChartInterval/ChartInterval";
import { ChartIntervalFillWork } from "../ChartInterval/_work/withWork";
import Portal from "shared/ui/atoms/Portal";
import ButtonBase from "shared/ui/controls/ButtonBase";
import TableReusableHead, { TableReusableHeaderCell } from "shared/ui/dataDisplay/TableReusable/TableReusableHead";
import TableReusableRow from "shared/ui/dataDisplay/TableReusable/TableReusableRow";
import DynamicTitleInput from "shared/ui/inputs/DynamicTitleInput/DynamicTitleInput";

import { CHART_MAX_LRU_COLORS, CHART_STATUSES_COLOR_PALETTE, CHART_STATUSES_DEFAULT_CONFIG } from "../../constants";

import { useChartUnitMultiplier } from "../../hooks/useChartUnitMultiplier";

import CrossIcon from "images/icons/CrossIcon";

import styles from "./ChartLegendEdit.module.scss";

export interface ChartLegendEditProps {
  tab: CHART_TABS;
  onClose: () => void;
}

const nowDate = moment().format("YYYY-MM-DD");

const IntervalPreview: React.FC<{
  statuses: ChartSatusConfig[CHART_TABS];
  percentages: Record<ChartStatusTypeWork | ChartStatusTypeResources, number>;
}> = ({ statuses, percentages }) => {
  const unitMultiplier = useChartUnitMultiplier();
  return (
    <ChartInterval
      intervalLeftRem={0}
      intervalWidthRem={2 * unitMultiplier}
      start={nowDate}
      end={nowDate}
      id={Math.random().toString()}
      projectId={0}
      intervalData={percentages}
      openModal={() => {}}
      className={styles.interval}
      inlinePopup
    >
      <ChartIntervalFillWork
        percentages={percentages}
        // @ts-ignore
        statuses={statuses}
      />
    </ChartInterval>
  );
};

const StatusEdit: React.FC<{
  title: string;
  color: string;
  percentage: number;
  index: number;
  onChangeStatus: (newDetails: Partial<ChartStatusDetails>) => void;
  onChangePercentage: (newPercentage: number) => void;
  rluColors: string[];
}> = ({ title, color, index, percentage, onChangeStatus, onChangePercentage, rluColors }) => {
  const onChangeTitle = (newTitle: string) => {
    onChangeStatus({ title: newTitle });
  };
  const onChangeColor = (newColor: string) => {
    onChangeStatus({ color: newColor });
  };
  const [mockInputColor, setMockInputColor] = useState(color);
  const changeColorViaInput = useCallback(
    debounce((v: string) => {
      onChangeColor(v);
    }, 150),
    []
  );

  const onInputColorChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newColor = (e.target as HTMLInputElement).value;
    setMockInputColor(newColor);
    changeColorViaInput(newColor);
  };

  return (
    <TableReusableRow className={styles.controlsGrid} containerClassName={styles.row} isHoverable={false}>
      <span className={styles.statusIndex}>{index + 1}</span>
      <DynamicTitleInput
        title={title}
        onChange={onChangeTitle}
        size="normal"
        containerClassName={styles.statusTitle}
        inputClassName={styles.statusTitleInput}
      />
      <PopoverOverlay
        portalClassName={styles.colorPopupPortal}
        placement="right"
        className={styles.colorPopupTarget}
        content={
          <div className={styles.colorPopup}>
            <div className={styles.colorPopupRow}>
              <span>Выберите из палитры</span>
              <div
                className={styles.colorPopupPalette}
                style={{ gridTemplateColumns: `repeat(${CHART_MAX_LRU_COLORS}, 1fr)` }}
              >
                {CHART_STATUSES_COLOR_PALETTE.map((c) => (
                  <div
                    key={c}
                    className={styles.colorPopupPaletteItem}
                    style={{ backgroundColor: c }}
                    onClick={() => onChangeColor(c)}
                  />
                ))}
              </div>
            </div>
            <div className={styles.colorPopupRow}>
              <span>Укажите свой цвет</span>
              <label htmlFor={`status-color-${title}`} className={styles.statusColorLabel}>
                <div className={cn(styles.colorPopupPaletteItem, styles.colorPopupPalettePlus)} />
                <span>Новый цвет</span>
                <input
                  className={styles.statusColor}
                  type="color"
                  value={mockInputColor}
                  onChange={onInputColorChange}
                  id={`status-color-${title}`}
                />
              </label>
            </div>
            {rluColors.length > 0 ? (
              <div className={styles.colorPopupRow}>
                <span>Последние использованные</span>
                <div
                  className={styles.colorPopupPalette}
                  style={{ gridTemplateColumns: `repeat(${CHART_MAX_LRU_COLORS}, 1fr)` }}
                >
                  {rluColors.map((c) => (
                    <div
                      key={c}
                      className={styles.colorPopupPaletteItem}
                      style={{ backgroundColor: c }}
                      onClick={() => onChangeColor(c)}
                    />
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        }
      >
        <div className={styles.statusColorDisplay} style={{ backgroundColor: color }} />
      </PopoverOverlay>

      <input
        type="range"
        min={0}
        max={100}
        value={percentage}
        onChange={(e) => onChangePercentage(Number(e.target.value))}
        className={styles.statusRange}
      />
    </TableReusableRow>
  );
};

const ChartLegendEdit: React.FC<ChartLegendEditProps> = ({ tab, onClose }) => {
  const dispatch = useDispatch();
  const chartStatuses = useSelector(chartStatusesSelector);
  const rluColors = useSelector(chartStatusesRLUColorsSelector);
  const [modifiedStatuses, setModifiedStatuses] = useState<ChartStatusDetailed>(chartStatuses[tab]);
  const [previewPercentages, setPreviewPercentages] = useState(
    Object.fromEntries(Object.keys(chartStatuses[tab]).map((k) => [k, Math.round(5 + Math.random() * 95)])) as Record<
      ChartStatusTypeWork | ChartStatusTypeResources,
      number
    >
  );

  useEffect(() => {
    setModifiedStatuses(chartStatuses[tab]);
  }, [chartStatuses[tab]]);

  const onChangeStatus = useCallback(
    (statusKey: string) => (values: Partial<ChartStatusDetails>) => {
      setModifiedStatuses((prev) => ({
        ...prev,
        // @ts-ignore
        [statusKey]: { ...prev[statusKey], ...values },
      }));
      if (values.color) {
        dispatch(chartActions.pushRLUColor(values.color));
      }
    },
    []
  );

  const onChangePercentage = useCallback(
    (statusKey: string) => (value: number) => {
      setPreviewPercentages((prev) => ({
        ...prev,
        [statusKey]: value,
      }));
    },
    []
  );

  const onSave = useCallback(() => {
    dispatch(updateChartStatuses(modifiedStatuses));
    onClose();
  }, [tab, modifiedStatuses]);

  const onReset = () => {
    setModifiedStatuses(CHART_STATUSES_DEFAULT_CONFIG[tab]);
  };

  return (
    <Portal>
      <div className={styles.container}>
        <div className={styles.shadow} />

        <div className={styles.content}>
          <div className={styles.closeButtonBlock} onClick={onClose}>
            <CrossIcon width={"1rem"} color={"#fff"} />
          </div>
          <div className={styles.modalHeading}>
            <span>Управление статусами на графике</span>
            <ButtonBase tertiary onClick={onReset} small>
              По умолчанию
            </ButtonBase>
          </div>
          <div className={styles.preview}>
            <IntervalPreview statuses={modifiedStatuses} percentages={previewPercentages} />
          </div>
          <div>
            <TableReusableHead className={styles.controlsGrid}>
              <TableReusableHeaderCell>№</TableReusableHeaderCell>
              <TableReusableHeaderCell>Наименование статуса</TableReusableHeaderCell>
              <TableReusableHeaderCell>Цвет</TableReusableHeaderCell>
              <TableReusableHeaderCell>Предпросмотр</TableReusableHeaderCell>
            </TableReusableHead>
            {Object.entries(modifiedStatuses || {}).map(([status, details], index) => (
              <StatusEdit
                key={status}
                title={details.title}
                color={details.color}
                index={index}
                // @ts-ignore
                percentage={previewPercentages[status]}
                onChangeStatus={onChangeStatus(status)}
                onChangePercentage={onChangePercentage(status)}
                rluColors={rluColors}
              />
            ))}
          </div>
          <div className={styles.modalButtons}>
            <ButtonBase secondary onClick={onClose}>
              Отменить
            </ButtonBase>
            <ButtonBase primary onClick={onSave}>
              Сохранить
            </ButtonBase>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default React.memo(ChartLegendEdit);
