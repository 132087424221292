import React, { useEffect, useMemo } from "react";

import { IBuildingPropsPermissions } from "components/pages/Building/Building";
import { DOCUMENTATION } from "components/pages/Building/constants";
import { TabsEnum } from "components/pages/Building/enums";

import BlueprintsLevel from "./widgets/BlueprintsLevel/BlueprintsLevel";
import BlueprintsSetsLevel from "./widgets/BlueprintsSetsLevel/BlueprintsSetsLevel";
import TemplateBase from "features/templates/TemplateBase/TemplateBase";
import TabBar from "shared/ui/controls/TabBar/TabBarNotLinks";

import { useLocationArray } from "utils/hooks/useLocationArray";

import styles from "./DocumentationSets.module.scss";

interface IProps {
  activeId?: string;
  tabs?: [{ link: string; text: string; id: string; className?: string }];
  onTabChange?: (tab: TabsEnum) => void;
  permissions: IBuildingPropsPermissions;
}

const Blueprints: React.FC<IProps> = ({ activeId, onTabChange, tabs, permissions }) => {
  const locationsArr = useLocationArray();
  const blueprintsSetId = useMemo(() => {
    const param = locationsArr.at(-1);
    if (param === DOCUMENTATION) {
      return "";
    }
    return param;
  }, [locationsArr]);

  return (
    <TemplateBase>
      {!blueprintsSetId && (
        <BlueprintsSetsLevel permissions={permissions}>
          {tabs && (
            <TabBar
              tabs={tabs}
              containerClassName={styles.tabs}
              activeId={DOCUMENTATION}
              onClick={onTabChange as any}
            />
          )}
        </BlueprintsSetsLevel>
      )}
      {blueprintsSetId && (
        <BlueprintsLevel drawingsSetId={blueprintsSetId} permissions={permissions}>
          {tabs && (
            <TabBar
              tabs={tabs}
              containerClassName={styles.tabs}
              activeId={DOCUMENTATION}
              onClick={onTabChange as any}
            />
          )}
        </BlueprintsLevel>
      )}
    </TemplateBase>
  );
};

export default React.memo(Blueprints);
