import React, { SyntheticEvent, useMemo, useState } from "react";

import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";

import PopoverChart, { IPopoverChartProps } from "../PopoverChart/PopoverChart";

import { IInfoIndicators } from "../../../../../types";
import { COLORS_INFO as COLORS } from "../constants";

import styles from "./InfoPopover.module.scss";

export interface IInfoPopoverProps {
  indicators: IInfoIndicators;
  measure?: string;
}

const InfoPopover: React.FC<IInfoPopoverProps> = ({ indicators, measure }) => {
  const [open, setOpen] = useState(false);

  /* @ts-ignore */
  const { chartData, dataAll }: Pick<IPopoverChartProps, "chartData" | "dataAll"> = useMemo(() => {
    const { count, count_accepted, count_completed, count_on_stock, count_to_pay, count_issued } = indicators;
    const finalCount = Number(count) || 0;
    const finalCompleted = Number(count_completed) || 0;
    const finalAccepted = Number(count_accepted) || 0;
    const finalToPay = Number(count_to_pay) || 0;
    const finalOnStock = Number(count_on_stock) || 0;
    const finalIssued = Number(count_issued) || 0;
    const dataAll = {
      name: "Количество",
      value: finalCount,
    };
    return {
      chartData: [
        count_completed !== undefined && {
          name: "Выполнено",
          value: finalCompleted,
          key: "completed",
          percent: (dataAll.value && Math.round((finalCompleted * 100) / dataAll.value)) || 0,
        },
        count_on_stock !== undefined && {
          name: `На складе`,
          value: finalOnStock,
          key: "on_stock",
          percent: (dataAll.value && Math.round((finalOnStock * 100) / dataAll.value)) || 0,
        },
        count_issued !== undefined && {
          name: `Выдано`,
          value: finalIssued,
          key: "issued",
          percent: (dataAll.value && Math.round((finalIssued * 100) / dataAll.value)) || 0,
        },
        count_accepted !== undefined && {
          name: "Принято",
          value: finalAccepted,
          key: "accepted",
          percent: (dataAll.value && Math.round((finalAccepted * 100) / dataAll.value)) || 0,
        },
        count_to_pay !== undefined && {
          name: "К оплате",
          value: finalToPay,
          key: "to_pay",
          percent: (dataAll.value && Math.round((finalToPay * 100) / dataAll.value)) || 0,
        },
      ].filter((x) => x),
      dataAll,
    };
  }, [indicators]);

  const handleInteraction = (isOpenNew: boolean, e: SyntheticEvent<HTMLElement, Event> | undefined) => {
    e?.stopPropagation();
    setOpen(isOpenNew);
  };

  return (
    <PopoverOverlay
      isOpen={open}
      openType="click"
      onInteraction={handleInteraction}
      placement="bottom"
      popoverBorderColor="primary"
      content={
        <div className={styles.infoPopoverContainer}>
          <span className={styles.infoPopoverTitle}>Прогресс выполнения</span>
          <div className={styles.infoPopoverHeading}>
            <span>Количество:</span>
            <span>{`${dataAll?.value} (${measure || ""})`}</span>
          </div>
          <PopoverChart chartData={chartData} dataAll={dataAll} colors={COLORS} />
          <div className={styles.legend}>
            {chartData.map((x, index) => (
              <React.Fragment key={index}>
                <div className={styles.infoPopoverItemMarker}>
                  {/* @ts-ignore */}
                  <div className={styles.square} style={{ backgroundColor: COLORS[x.key] }} />
                  <span>{x.name}:</span>
                </div>
                <span>{`${x.value > 0 && x.percent < 1 ? "<1" : x.percent}%`}</span>
                <span>{`${x.value} (${measure || ""})`}</span>
              </React.Fragment>
            ))}
          </div>
        </div>
      }
    >
      <div className={styles.info} onMouseLeave={() => setOpen(false)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <g id="Сгруппировать_7264" data-name="Сгруппировать 7264" transform="translate(-189.063)">
            <circle
              id="Эллипс_48574"
              data-name="Эллипс 48574"
              cx="10"
              cy="10"
              r="10"
              transform="translate(189.063)"
              fill="#FFFFFF"
            />
            <path
              id="Контур_5801"
              data-name="Контур 5801"
              d="M2.544,0H1.456V-7.488H2.544Zm.224-10.368a.709.709,0,0,1-.24.552A.773.773,0,0,1,2-9.6a.773.773,0,0,1-.528-.216.709.709,0,0,1-.24-.552.709.709,0,0,1,.24-.552A.773.773,0,0,1,2-11.136a.773.773,0,0,1,.528.216A.709.709,0,0,1,2.768-10.368Z"
              transform="translate(197.063 15)"
              fill="#000000"
            />
          </g>
        </svg>
      </div>
    </PopoverOverlay>
  );
};

export default React.memo(InfoPopover);
