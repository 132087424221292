import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, RouteComponentProps, Switch, useParams } from "react-router-dom";
import { Action, compose } from "redux";

import { getDetail, objectDetailSelector } from "redux/modules/common/building/object/nowObject";
import { clearIntervals } from "redux/modules/common/building/process";
import { loadSections, resetSectionsAction } from "redux/modules/common/building/sections/sections";

import { IBuildingPropsPermissions } from "components/pages/Building/Building";
import EstimateIndicators from "components/pages/Building/components/EstimateIndicators/EstimateIndicators";
import ProjectHeader from "components/pages/Building/components/ProjectHeader/ProjectHeader";
import ProjectSubsectionsHeader from "components/pages/Building/components/ProjectHeader/ProjectSubsectionsHeader";
import ProjectNavbar from "components/pages/Building/components/ProjectNavbar/ProjectNavbar";
import ProjectSharingBar from "components/pages/Building/components/ProjectSharingBar/ProjectSharingBar";
import { DOCUMENTATION, PLAN, PROGRESS } from "components/pages/Building/constants";
import { TabsEnum } from "components/pages/Building/enums";
import { IExpenditureTypesFiltersInBuilding } from "components/pages/Building/types";
import Blueprints from "pages/Estimate/Blueprints/Blueprints";
import { ESTIMATE_STATES_IDS } from "pages/Handler/ui/ProHandler/constants";

import ConstructionControlDocumentation from "./ConstructionControlDocumentation/ConstructionControlDocumentation";
import CCSubsectionHeader from "./ui/CCSubsectionHeader/CCSubsectionHeader";
import ConstructionControlObjectBreadcrumbs from "./ui/ConstructionControlObjectBreadcrumbs/ConstructionControlObjectBreadcrumbs";
import ConstructionControlObjectSections from "./ui/ConstructionControlObjectSections/ConstructionControlObjectSections";
import ConstructionControlObjectSubSections from "./ui/ConstructionControlObjectSubsections/ConstructionControlObjectSubSections";
import TemplateBase from "features/templates/TemplateBase/TemplateBase";

import styles from "./ConstructionControlObject.module.scss";

interface IBuildingProps {
  editable?: boolean;
  permissions: IBuildingPropsPermissions;
  location: Location;
  match: RouteComponentProps["match"];
  history: RouteComponentProps["history"];
}

const DEFAULT_BUILDING_FILTERS = {
  material: true,
  machine: true,
  transport: true,
  equipment: true,
};

const TABS_IDS = [PROGRESS, DOCUMENTATION];

const ConstructionControlObject: React.FC<IBuildingProps> = ({
  match,
  location,
  editable = true,
  permissions,
  history,
}) => {
  const { objectId } = useParams<any>();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState<IExpenditureTypesFiltersInBuilding | null>(DEFAULT_BUILDING_FILTERS);
  const [date, setDate] = useState(() => moment());
  const [selectingMonthsCount, setSelectingMonthsCount] = useState(() => 1);
  const [isIndicatorsOpen, setIsIndicatorsOpen] = useState(true);

  const activeTabId = useMemo(() => {
    const partOfSectionPath = location.pathname.replace(match.url, "");

    return TABS_IDS.find((id: string) => `/${id}` === partOfSectionPath || `/${id}/` === partOfSectionPath) || "plan";
  }, [location.pathname, match.url]);

  const handleChangeActiveTab = useCallback(
    (tabId: string) => {
      history.push(`${match.url}/${tabId}/`);
    },
    [history, match.url]
  );

  const startOfMonthFormattedDate = useMemo(() => moment(date).startOf("month").format("YYYY-MM-DD"), [date]);
  const endOfMonthFormattedDate = useMemo(
    () =>
      moment(date)
        .add(selectingMonthsCount - 1, "month")
        .endOf("month")
        .format("YYYY-MM-DD"),
    [date, selectingMonthsCount]
  );

  useEffect(() => {
    dispatch(getDetail(objectId));
    //dispatch(clearSelecteds());
    //setSubSection(null);
    compose<Action>(dispatch, loadSections)({ estimateStateId: ESTIMATE_STATES_IDS.PRODUCTION, buildingId: objectId });
    return () => {
      dispatch(clearIntervals());
      dispatch(resetSectionsAction());
    };
  }, [objectId]);

  const objectInfo = useSelector(objectDetailSelector);

  const tabs = React.useMemo(() => {
    const res = [];
    if (permissions.canViewProgress) {
      res.push({
        text: "Прогресс",
        id: PROGRESS,
        className: styles.tab,
      });
    }
    /* if (permissions.canViewPlan) {
      res.push({
        text: "Планирование",
        id: PLAN,
        className: styles.tab,
      });
    } */
    if (permissions.canViewDrawings) {
      res.push({
        text: "Документация",
        id: DOCUMENTATION,
        className: styles.tab,
      });
    }
    return res;
  }, [permissions.canViewPlan, permissions.canViewProgress, permissions.canViewDrawings]);

  if (activeTabId !== PROGRESS) {
    return (
      <Blueprints
        permissions={permissions}
        activeId={activeTabId}
        tabs={tabs as any}
        onTabChange={(tab: TabsEnum) => {
          if ((tab as string) === activeTabId) return;
          handleChangeActiveTab(tab);
        }}
      />
    );
  }

  return (
    <TemplateBase contentClassName={styles.outerTemplate} breadcrumbs={<ConstructionControlObjectBreadcrumbs />}>
      <div className={styles.head}>
        <ProjectNavbar
          isTabs={true}
          onTabChange={(tab: TabsEnum) => {
            if (tab === activeTabId) return;
            /* dispatch(clearIntervals());
            dispatch(setLoadingIntervals(true));*/
            handleChangeActiveTab(tab);
          }}
          activeTab={activeTabId}
          filters={filter}
          setFilters={setFilter}
          timeSliderDate={date}
          onChangeTimeSlider={setDate}
          selectingMonthsCount={selectingMonthsCount}
          setSelectingMonthsCount={setSelectingMonthsCount}
          permissions={permissions}
          /* @ts-ignore */
          location={location}
          match={match}
          history={history}
          _TEMP_hidePlan
        />
        <EstimateIndicators id={objectId} isOpen={isIndicatorsOpen} setIsOpen={setIsIndicatorsOpen} />
        <Switch>
          <Route
            exact
            path="/construction-control/projects/:projectId/object/:objectId/:tab/"
            render={() => (
              <ProjectHeader
                isExists
                back={"backPath"}
                name={objectInfo?.name}
                isShared={false}
                isShowSharedButtons //={isShowSharedButtons}
                onApproveSharing={() => {}} //={approveSharingHandler}
                onDeclineSharing={() => {}} //={declineSharingHandler}
                isSharedStatusTransferred //={objectInfo?.shared_status === "transferred"}
              />
            )}
          />
          <Route
            exact
            path="/construction-control/projects/:projectId/object/:objectId/:tab/:sectionId/"
            render={() => (
              <>
                <CCSubsectionHeader objectInfo={objectInfo} />
              </>
            )}
          />
        </Switch>
        <ProjectSharingBar
          handleOpenModal={() => {}} //={handleOpenCreateGroupModal}
          isExists={false} //={canSharing || canGroup}
          canSharing //={canSharing}
          canGroup //={canGroup}
          sharingCallback={() => {}} //={sharingCallback}
          filters={filter}
          canAddOutOfEstimate
          onAddOutOfEstimateClick={() => {}}
        />
      </div>
      <div className={styles.container}>
        <Switch>
          {/* <Route
            exact
            path={[
              "/construction-control/projects/:projectId/object/:objectId/documentation/",
              "/construction-control/projects/:projectId/object/:objectId/documentation/:setId",
            ]}
            render={() => <ConstructionControlDocumentation />}
          /> */}
          <Route
            exact
            path="/construction-control/projects/:projectId/object/:objectId/:tab/"
            render={() => (
              <ConstructionControlObjectSections
                permissions={permissions}
                startOfMonthFormattedDate={startOfMonthFormattedDate}
                endOfMonthFormattedDate={endOfMonthFormattedDate}
              />
            )}
          />
          <Route
            exact
            path="/construction-control/projects/:projectId/object/:objectId/:tab/:sectionId/"
            render={() => (
              <ConstructionControlObjectSubSections
                permissions={permissions}
                editable={editable}
                isIndicatorsOpen={isIndicatorsOpen}
                filters={filter as any}
                startOfMonthFormattedDate={startOfMonthFormattedDate}
                endOfMonthFormattedDate={endOfMonthFormattedDate}
              />
            )}
          />
        </Switch>
      </div>
    </TemplateBase>
  );
};

export default ConstructionControlObject;
