import { useCallback, useEffect, useRef } from "react";

export interface IUseChartIntervalPopup {
  containerRef: React.RefObject<HTMLElement>;
  inlinePopup?: boolean;
}

export const useChartIntervalPopup = ({ containerRef, inlinePopup }: IUseChartIntervalPopup) => {
  const popupRef = useRef<HTMLElement | null>(null);

  const movePopup = useCallback((e: MouseEvent) => {
    requestAnimationFrame(() => {
      if (!containerRef?.current || !popupRef.current) return;
      if (inlinePopup) {
        popupRef.current.style.left = "0px";
        popupRef.current.style.transform = `none`;
      } else {
        const bounds = containerRef.current.getBoundingClientRect();
        const x = Math.max(bounds.left + 8, Math.min(e.clientX, bounds.right - 8));
        popupRef.current.style.left = "0px";
        popupRef.current.style.transform = `translateX(calc(${x}px - 75%))`;
      }
    });
  }, []);

  useEffect(() => {
    const container = containerRef?.current;

    container?.addEventListener("mousemove", movePopup);
    container?.addEventListener("mouseenter", movePopup);

    return () => {
      container?.removeEventListener("mousemove", movePopup);
      container?.removeEventListener("mouseenter", movePopup);
    };
  }, [movePopup, inlinePopup]);

  return {
    popupRef,
  };
};
