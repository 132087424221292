import cn from "classnames";
import React, { useContext } from "react";
import { useSelector } from "react-redux";

import { chartStatusesSelector, chartTabSelector } from "redux/modules/common/chart/selectors";
import { ChartStatusDetails, IChartFactResourceCompletion } from "redux/modules/common/chart/types";

import MaterialStatusBlock from "../../../Manufacturing/components/Month/components/InfoPopup/components//MaterialStatusBlock/MaterialStatusBlock";
import StatusBlock from "../../../Manufacturing/components/Month/components/InfoPopup/components/StatusBlock/StatusBlock";
import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";

import { ChartContext } from "../../Chart";
import { Placement } from "@blueprintjs/popover2";

import {
  INTERVAL_NAME_MAP,
  INTERVAL_TYPES,
  REMARKS_COLOR,
  WORK_STATUSES,
  getWorkStatus,
} from "../../../Manufacturing/constants";

import { AlertIcon } from "../../../../../images/icons/AlertIcon";

import styles from "./ChartIntervalPopup.module.scss";

const getHighestMaterialStatus = (
  materialCompletion: IChartFactResourceCompletion
): keyof IChartFactResourceCompletion => {
  if (+materialCompletion.to_pay > 0) return "to_pay";
  if (+materialCompletion.accepted > 0) return "accepted";
  if (+materialCompletion.issued > 0) return "issued";
  if (+materialCompletion.on_stock > 0) return "on_stock";
  if (+materialCompletion.ordered > 0) return "ordered";
  if (+materialCompletion.todo > 0) return "todo";
  return "todo";
};

const ChartIntervalPopupStatus: React.FC<{
  details: ChartStatusDetails;
  count?: string | number;
  measure?: string;
}> = ({ details, count, measure }) => {
  return (
    <div className={styles.statusBlock}>
      <div className={styles.status} style={{ backgroundColor: details.color }} />
      <div className={styles.indicators}>
        <span className={styles.statusName}>{details.title}</span>
        {count !== undefined ? (
          <span className={cn(styles.statusCount, styles.textPrimary)}>{`${count} ${
            measure ? `(${measure})` : ""
          }`}</span>
        ) : null}
      </div>
    </div>
  );
};

export interface IChartIntervalPopupProps {
  data: any;
  openModal: () => void;
  type: INTERVAL_TYPES;
  /** Отображаемая дата начала-окончания отрезка */
  intervalDates: string;
  /** Количество замечаний */
  remarksCount?: number;
  /** Необходим ли сдвиг плана */
  hasShift?: boolean;
  /** Открывает модальное окно сдвига плана */
  openShiftModal?: () => void;
  children?: React.ReactNode;
  usePortal?: boolean;
  placement?: Placement;
}

const ChartIntervalPopup = React.forwardRef(
  (
    {
      data,
      type,
      intervalDates,
      openModal,
      children,
      remarksCount,
      openShiftModal,
      hasShift = false,
      usePortal = true,
      placement,
    }: IChartIntervalPopupProps,
    ref
  ) => {
    const { contentRef } = useContext(ChartContext);
    const chartStatuses = useSelector(chartStatusesSelector);
    const chartTab = useSelector(chartTabSelector);
    const currentStatuses = chartTab ? chartStatuses[chartTab] : {};
    const workStatus = type === INTERVAL_TYPES.work && data ? getWorkStatus(data) : WORK_STATUSES.default;

    const isMaterialOrMim = [
      INTERVAL_TYPES.material,
      INTERVAL_TYPES.equipment,
      INTERVAL_TYPES.machine,
      INTERVAL_TYPES.transport,
    ].includes(type);

    const materialStatus = isMaterialOrMim ? getHighestMaterialStatus(data) : "";

    const measure = data?.measure || data?.expenditure?.measure || data?.group?.measure || "";

    const isWorkIndicatorsShown = type === INTERVAL_TYPES.work && workStatus !== WORK_STATUSES.default;

    const isMaterialStatusesShown = isMaterialOrMim && materialStatus;

    const workIndicators = isWorkIndicatorsShown && {
      completed: Number(data.completed),
      accepted: Number(data.accepted),
      // confirmed: Number(data.confirmed),
      todo: Number(data.todo),
      to_pay: Number(data.to_pay),
    };

    const materialIndicators = isMaterialStatusesShown && {
      plans: Number(data.count),
      ordered: Number(data.ordered),
      on_stock: Number(data.on_stock),
      issued: Number(data.issued),
      accepted: Number(data.accepted),
      todo: Number(data.todo),
      to_pay: Number(data.to_pay),
    };

    return (
      <PopoverOverlay
        // @ts-ignore
        ref={ref}
        openType="hover"
        className={styles.popoverContainer}
        placement={placement || "bottom"}
        popoverBorderColor={
          // @ts-ignore
          (isMaterialOrMim ? currentStatuses[materialStatus]?.color : currentStatuses[workStatus]?.color) || "default"
        }
        usePortal={usePortal}
        portalContainer={(contentRef?.current as HTMLElement) || undefined}
        content={
          <div className={styles.container}>
            <div>
              <div className={styles.header}>
                <span>{INTERVAL_NAME_MAP[type]}</span>
                <span className={styles.intervalDates}>{intervalDates}</span>
              </div>
              {workIndicators ? (
                <div className={cn(styles.item, styles.statuses)}>
                  {Object.entries(workIndicators).map(([key, value]) =>
                    value > 0 ? (
                      <ChartIntervalPopupStatus
                        //@ts-ignore
                        details={currentStatuses[key]}
                        count={value}
                        measure={measure}
                        key={`${key}_${value}`}
                      />
                    ) : null
                  )}
                </div>
              ) : null}
              {materialIndicators ? (
                <div className={cn(styles.item, styles.statuses)}>
                  {Object.entries(materialIndicators).map(([key, value]) =>
                    value > 0 ? (
                      <ChartIntervalPopupStatus
                        //@ts-ignore
                        details={currentStatuses[key]}
                        count={value}
                        measure={measure}
                        key={`${key}_${value}`}
                      />
                    ) : null
                  )}
                </div>
              ) : null}

              {remarksCount ? (
                <div className={cn(styles.item, styles.remarks)}>
                  <span>
                    <AlertIcon fill={REMARKS_COLOR} width={16} /> Замечания:
                  </span>
                  <span className={styles.textPrimary}>{remarksCount}</span>
                </div>
              ) : null}

              {hasShift ? (
                <div className={styles.shift} onClick={openShiftModal}>
                  Требуется
                  <br />
                  корректировка плана
                </div>
              ) : null}

              <div className={styles.buttons}>
                <div onClick={openModal} className={styles.cardButton}>
                  Посмотреть
                </div>
              </div>
            </div>
          </div>
        }
      >
        {children}
      </PopoverOverlay>
    );
  }
);

export default React.memo(ChartIntervalPopup);
