import React, { createContext, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { objectsBreadcrumbsSelector } from "redux/modules/common/building/objects";
import { chartActions } from "redux/modules/common/chart/actions";
import {
  chartHashSelector,
  chartLoadersSelector,
  chartProjectIdsSelector,
  chartProjectSliceIndexSelector,
  chartScrollTouchedYearsSelector,
  chartTabSelector,
  chartTreeSelector,
} from "redux/modules/common/chart/selectors";
import { chartMarkCheckpointDate, dropChart, reloadChart, setChartProjectIds } from "redux/modules/common/chart/thunks";
import { CHART_LOADERS, CHART_TABS } from "redux/modules/common/chart/types";

import ChartActions from "./components/ChartActions/ChartActions";
import ChartControls from "./components/ChartControls/ChartControls";
import { useChartTabs } from "./components/ChartControls/ChartControls.utils/useChartTabs";
import ChartLegend from "./components/ChartLegend/ChartLegend";
import ChartTree from "./components/ChartTree/ChartTree";
import TemplateSimple from "_LEGACY/UI/_LEGACY_TemplateSimple/TemplateSimple";
import ForbiddenPage from "app/routes/components/ForbiddenPage/ForbiddenPage";

import { Xwrapper } from "react-xarrows";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import { Checkpoint } from "widgets/AddCheckpoint";
import { ViewCheckpoint } from "widgets/ViewCheckpoint";

import { PROJECTS_BUCKET_SIZE } from "./constants";
import { IRouterParamsWithObjectId } from "types/routerTypes";

import { useChartConfig } from "./hooks/useChartConfig";
import { useChartDrag } from "./hooks/useChartDrag";
import { useChartPointerCreatePlan } from "./hooks/useChartPointerCreatePlan";
import { useChartScroll } from "./hooks/useChartScroll";
import { useUrlModule } from "utils/hooks/useUrlModule";

import styles from "../Manufacturing/Manufacturing.module.scss";

export interface IChartContext {
  containerRef: React.RefObject<HTMLDivElement | null> | undefined;
  contentRef: React.RefObject<HTMLDivElement | null> | undefined;
  projectId: string;
}

export const ChartContext = createContext<IChartContext>({
  containerRef: undefined,
  contentRef: undefined,
  projectId: "",
});

const Chart: React.FC = () => {
  const tab = useSelector(chartTabSelector);
  const treeRoot = useSelector(chartTreeSelector);
  const loaders = useSelector(chartLoadersSelector);
  const touchedYears = useSelector(chartScrollTouchedYearsSelector);
  const chartHash = useSelector(chartHashSelector);
  const allProjects = useSelector(objectsBreadcrumbsSelector);
  const chartProjectIds = useSelector(chartProjectIdsSelector);
  const chartProjectSliceIndex = useSelector(chartProjectSliceIndexSelector);
  const module = useUrlModule();
  const dispatch = useDispatch();
  const { objectId: projectId } = useParams<IRouterParamsWithObjectId>();
  /** Реф контейнера дерева расценок и отрезков */
  const calendarRef = useRef<HTMLDivElement | null>(null);
  /** Реф контента графика. Нужен для рендера попапов */
  const contentRef = useRef<HTMLDivElement | null>(null);
  const prevChartHash = useRef<number | undefined>(chartHash);
  const prevTouchedYears = useRef<string>(JSON.stringify(touchedYears));
  const prevChartProjectSliceIndex = useRef<number | undefined>(chartProjectSliceIndex);
  const prevTab = useRef<CHART_TABS | undefined | null>(tab);
  const { chartTabs } = useChartTabs();

  useEffect(() => {
    if (!tab) return;
    const ty = JSON.stringify(touchedYears);
    const updateRefs = () => {
      prevChartHash.current = chartHash;
      prevTab.current = tab;
      prevChartProjectSliceIndex.current = chartProjectSliceIndex;
      prevTouchedYears.current = ty;
    };

    const needTreeLoading =
      prevChartHash.current !== chartHash ||
      (prevTab.current !== tab &&
        (!treeRoot?.children?.[0]?._id ||
          treeRoot.children.length !== chartProjectIds.length ||
          treeRoot?.children?.[0]?.id !== chartProjectIds?.[0]));

    if (!chartProjectIds.length) {
      dispatch(chartActions.setLoader(CHART_LOADERS.BLOCKING, true));
      dispatch(
        setChartProjectIds(
          projectId
            ? [+projectId]
            : allProjects.results?.slice(0, PROJECTS_BUCKET_SIZE)?.map(({ id }: { id: number }) => id) || []
        )
      );
    }

    if (prevTab.current !== tab) {
      dispatch(chartActions.setLoader(CHART_LOADERS.BLOCKING, true));
    }

    if (
      chartProjectIds.length > 0 &&
      treeRoot &&
      treeRoot.children?.length === chartProjectIds.length &&
      prevChartProjectSliceIndex.current === chartProjectSliceIndex
    ) {
      dispatch(chartActions.setLoader(CHART_LOADERS.BLOCKING, false));
    }

    if (!projectId && allProjects.results && prevChartProjectSliceIndex.current !== chartProjectSliceIndex) {
      dispatch(
        setChartProjectIds(
          allProjects.results
            .slice(0, PROJECTS_BUCKET_SIZE * (chartProjectSliceIndex + 1))
            .map(({ id }: { id: number }) => id)
        )
      );
      dispatch(
        reloadChart({
          needTreeLoading: true,
          forceProjectIds: allProjects.results
            .slice(chartProjectSliceIndex * PROJECTS_BUCKET_SIZE, PROJECTS_BUCKET_SIZE * (chartProjectSliceIndex + 1))
            .map(({ id }: { id: number }) => id),
          indexShift: chartProjectSliceIndex * PROJECTS_BUCKET_SIZE,
        })
      );
      updateRefs();
      return;
    }

    if (needTreeLoading || prevTouchedYears.current !== ty) {
      dispatch(reloadChart({ needTreeLoading }));
    }

    updateRefs();
  }, [
    tab,
    touchedYears,
    treeRoot,
    treeRoot?.children,
    chartHash,
    chartProjectIds,
    allProjects,
    chartProjectSliceIndex,
    projectId,
  ]);

  useEffect(() => {
    dispatch(dropChart(module, { statuses: true }));
  }, [projectId, module]);

  const { handleDateChange } = useChartScroll({ calendarRef, isLoading: !treeRoot?._id });

  useChartDrag({ targetRef: calendarRef });

  const { createPlanModal } = useChartPointerCreatePlan(calendarRef);

  useChartConfig();

  const onDeleteCheckpoint = useCallback((checkpoint: Checkpoint) => {
    dispatch(chartMarkCheckpointDate(checkpoint.check_point_date, -1));
  }, []);

  const onEditCheckpoint = useCallback((prevCheckpoint: Checkpoint, nextCheckpoint: Checkpoint) => {
    dispatch(chartMarkCheckpointDate(prevCheckpoint.check_point_date, -1));
    dispatch(chartMarkCheckpointDate(nextCheckpoint.check_point_date, 1));
  }, []);

  const onAddCheckpoint = useCallback((checkpoint: Checkpoint) => {
    dispatch(chartMarkCheckpointDate(checkpoint.check_point_date, 1));
  }, []);

  const chartContext: IChartContext = { containerRef: calendarRef, contentRef, projectId };

  if (!chartTabs?.length) {
    return (
      <TemplateSimple>
        <ForbiddenPage />
      </TemplateSimple>
    );
  }

  return (
    <TemplateSimple contentClassName={styles.template} dataTestId="page_chart">
      <ChartContext.Provider value={chartContext}>
        {/* @ts-ignore */}
        <div className={styles.container} inert={loaders.blocking ? "true" : undefined}>
          <ChartControls handleDateChange={handleDateChange} projectId={projectId} />
          <div className={styles.content} ref={contentRef}>
            {loaders.blocking ? (
              <Spinner />
            ) : (
              <>
                <Xwrapper>
                  <ChartTree tree={treeRoot} />
                </Xwrapper>
                {tab ? (
                  <div className={styles.legend}>
                    <ChartLegend tab={tab} />
                  </div>
                ) : null}
                <div className={styles.diagramActionsWrapper}>
                  <ChartActions />
                </div>
              </>
            )}
          </div>
        </div>
        {createPlanModal}
      </ChartContext.Provider>
      <ViewCheckpoint
        objectId={projectId}
        deleteSuccessCallback={onDeleteCheckpoint}
        editSuccessCallback={onEditCheckpoint}
        addCheckpointSuccessCallback={onAddCheckpoint}
      />
    </TemplateSimple>
  );
};

export default Chart;
