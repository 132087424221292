import cn from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { chartActions } from "redux/modules/common/chart/actions";
import { chartActionsSelector, chartStatusesSelector } from "redux/modules/common/chart/selectors";
import {
  CHART_TABS,
  ChartModalType,
  ChartSatusConfig,
  IChartFactWork,
  IChartFactWorkCompletionType,
} from "redux/modules/common/chart/types";

import IntervalGroupModal from "components/UI/_TODO/Expenditure/components/IntervalGroupModal/IntervalGroupModal";
import ManufacturingModal from "components/pages/Manufacturing/components/modals/ManufacturingModal/ManufacturingModal";
import { INTERVAL_TYPES, SharedBraceStatusType } from "components/pages/Manufacturing/constants";

import type { IChartIntervalProps } from "../ChartInterval.typings";
import { CHART_Z_INDEX } from "../const";

import { AlertIcon } from "../../../../../../images/icons/AlertIcon";

import intervalStyles from "../ChartInterval.module.scss";
import styles from "./withWork.module.scss";

export interface IChartWorkIntervalProps
  extends Omit<
    IChartIntervalProps,
    "start" | "end" | "id" | "forwardRef" | "openModal" | "closeModal" | "intervalData" | "popupType"
  > {
  work: IChartFactWork;
  modalType: ChartModalType;
}

export const ChartIntervalFillWork: React.FC<{
  percentages: Record<IChartFactWorkCompletionType, number>;
  statuses: ChartSatusConfig[CHART_TABS.WORK];
}> = ({ percentages, statuses }) => {
  return (
    <div className={cn(styles.work, intervalStyles.ticketShape)}>
      {Object.entries(percentages).map(([key, percentage]) =>
        percentage > 0 ? (
          <div
            key={key}
            className={styles.intervalFill}
            // @ts-ignore
            style={{ width: `${Math.min(Number(percentage), 100)}%`, backgroundColor: statuses[key].color }}
          />
        ) : null
      )}
    </div>
  );
};

function withWork(Base: React.FC<IChartIntervalProps>) {
  return ({ work, modalType, ...props }: IChartWorkIntervalProps) => {
    const dispatch = useDispatch();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const diagramActions = useSelector(chartActionsSelector);
    const chartWorkStatuses = useSelector(chartStatusesSelector)?.[CHART_TABS.WORK] || {};

    const closeModal = useCallback(() => {
      setIsOpenModal(false);
    }, []);

    const openModal = useCallback(() => {
      setIsOpenModal(true);
    }, []);

    const updateSharedStatus = (status: SharedBraceStatusType) => {
      if (!work.is_shared || status === work.status) return;
      dispatch(chartActions.updateHash());
    };

    const isGroup = work.type === "group";

    const percentages: Record<IChartFactWorkCompletionType, number> = useMemo(() => {
      const completionMax = Math.max(
        Number(work.completed),
        Number(work.accepted),
        // TODO: детализировать статус "Подтверждено" в попапе
        // Number(work.confirmed),
        Number(work.todo),
        Number(work.to_pay)
      );
      if (completionMax <= 0) {
        return {
          completed: 0,
          accepted: 0,
          todo: 0,
          to_pay: 0,
        };
      }
      return {
        completed: Number(work.completed) > 0 ? (Number(work.completed) * 100) / completionMax : 0,
        accepted: Number(work.accepted) > 0 ? (Number(work.accepted) * 100) / completionMax : 0,
        // TODO: детализировать статус "Подтверждено" в попапе
        // confirmed: Number(work.confirmed) > 0 ? Number(work.confirmed) / completionMax : 0,
        todo: Number(work.todo) > 0 ? (Number(work.todo) * 100) / completionMax : 0,
        to_pay: Number(work.to_pay) > 0 ? (Number(work.to_pay) * 100) / completionMax : 0,
      };
    }, [work]);

    const remarksCount = (work.ticket_remarks_in_work?.length || 0) + (work.remarks_in_work?.length || 0);
    const isRemarkChipVisible = diagramActions.remarks_visible;

    return (
      <Base
        {...props}
        id={isGroup ? `group_${work.group?.id}` : `work_${work.expenditure?.id}`}
        start={work.start}
        end={work.end}
        openModal={openModal}
        // @ts-ignore
        closeModal={closeModal}
        intervalData={work}
        popupType={INTERVAL_TYPES.work}
        zIndex={CHART_Z_INDEX.WORK}
        remarksCount={remarksCount}
        sharedStatus={work.is_shared ? work.status : undefined}
        innerBeforeContent={
          <>
            {props.innerBeforeContent}
            {isRemarkChipVisible && !!remarksCount && (
              <div className={styles.remarksChip}>
                <AlertIcon fill={"white"} width={16} /> {remarksCount}
              </div>
            )}
          </>
        }
        children={
          <>
            {props.children}
            <ChartIntervalFillWork percentages={percentages} statuses={chartWorkStatuses} />
          </>
        }
        afterContent={
          <>
            {props.afterContent}
            {!isGroup && isOpenModal && (
              <ManufacturingModal
                isOpen
                onClose={closeModal}
                type="work"
                date_start={work.start}
                date_end={work.end}
                objectId={props.projectId.toString()}
                // @ts-ignore
                intervaldata={work.expenditure}
                expenditureId={
                  work.expenditure?.exp_id || work.expenditure?.cs_id || (work.expenditure?.ps_id as number)
                }
                sectionId={work.expenditure?.cs_id || (work.expenditure?.ps_id as number)}
                modalType={modalType}
                updateWorkStatus={updateSharedStatus}
              />
            )}
            {isGroup && isOpenModal && (
              <IntervalGroupModal
                isOpen
                onClose={closeModal}
                activeModule="facts"
                date_start={work.start}
                date_end={work.end}
                expenditureId={work.group?.group_id as number}
                expenditure={{}}
                // sectionName={work.section_name}
                objectId={props.projectId.toString()}
                sectionId={work.group?.cs_id as number}
                sectionName=""
              />
            )}
          </>
        }
      />
    );
  };
}

export default withWork;
